<template>
  <div>
    <b-modal id="modal-create-task" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" ok-title="Save" centered title="Create Task">
      <b-form>
        <b-form-group>
          <label for="taskName">Task Name</label>
          <b-form-input id="taskName" placeholder="Task Name" />
        </b-form-group>

        <b-form-group>
          <label for="description">Description</label>
          <b-form-textarea placeholder="Description" rows="3" />
        </b-form-group>

        <b-form-group>
          <label for="description">Project</label>
          <v-select label="name" :options="projects" />
        </b-form-group>

        <b-form-group>
          <label for="description">Assignee</label>
          <v-select label="name" multiple :options="users" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      projects: [
        {
          name: 'ATB',
        },
        {
          name: 'Senco',
        },
        {
          name: 'SFC',
        },
      ],

      users: [
        {
          name: 'Burak',
        },
        {
          name: 'Cuneyt',
        },
        {
          name: 'Orhan',
        },
      ],
    };
  },
};
</script>
