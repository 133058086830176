import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFolders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchFolders', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    saveFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dropdownUserAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownUserAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownCompanyAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownCompanyAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
