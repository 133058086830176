<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div class="row flex-nowrap width-scrool" style="overflow-x: auto;">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(board, index) in boardList" :key="'board_' + index">
        <div>
          <div class="rounded text-white p-25 mb-1" :style="`background-color: ${board.titleBg}`">
            <div class="d-flex justify-content-between align-items-center">
              <span class="ml-1" style="font-weight: 700;">{{ board.name }}</span>
              <feather-icon class="float-right mr-1" v-b-modal.modal-create-task icon="PlusCircleIcon" v-if="index == 0" size="16" style="cursor: pointer;" />
            </div>
          </div>
          <div id="container" class="scroll-container">
            <draggable class="list-group" tag="div" v-model="board.list" v-bind="dragOptions" @start="drag = true" @end="drag = false">
              <b-card class="ml-50 mr-50 " :style="{ 'border-left': `3px solid ${card.tagColor}` }" v-for="card in board.list" :key="card.order">
                <feather-icon icon="MoreVerticalIcon" size="14" style="position: absolute; top: 15px; right: 15px; cursor: pointer;" />

                <div class="card-text">
                  <h5 class="card-title">
                    {{ card.header.slice(0, 50) }}
                  </h5>
                  <p class="card-description">
                    {{ card.text.slice(0, 100) }}
                  </p>
                  <p class="text-danger">{{ card.date }}</p>
                </div>

                <div class="d-flex flex-wrap">
                  <div v-for="(user, userIndex) in card.assignedUsers" :key="userIndex" class="avatar-circle mr-25">
                    <img :src="user.imageSrc" alt="User Image" height="20px" class="avatar-circle-inner" />
                  </div>
                </div>

                <b-progress :max="card.max" class="mt-50">
                  <b-progress-bar :value="card.value" striped animated variant="success" :label="`${((card.value / card.max) * 100).toFixed(2)}%`"></b-progress-bar>
                </b-progress>
              </b-card>
            </draggable>
          </div>
        </div>
      </div>
    </div>

    <add-todo-modal></add-todo-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, VBModal, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import todoStoreModule from './todoStoreModule';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import VueContext from 'vue-context';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import draggable from 'vuedraggable';
import AddTodoModal from './AddTodoModal.vue';

export default {
  components: {
    AddTodoModal,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    VueContext,
    BBreadcrumb,
    draggable,
  },

  directives: {
    Ripple,
  },

  setup() {
    const todo_APP_STORE_MODULE_NAME = 'todo';
    // Register module
    if (!store.hasModule(todo_APP_STORE_MODULE_NAME)) store.registerModule(todo_APP_STORE_MODULE_NAME, todoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(todo_APP_STORE_MODULE_NAME)) store.unregisterModule(todo_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      loading: false,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 35,
        height: 35,
        class: 'm1',
      },
      drag: false,

      boardList: [
        {
          name: 'Pending',
          titleBg: '#005D8E',
          fixed: false,
          list: [
            {
              order: 1,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 2,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'blue',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 3,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'green',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
          ],
        },
        {
          name: 'Active',
          titleBg: '#00A7FF',
          fixed: false,
          list: [
            {
              order: 4,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 5,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'orange',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
          ],
        },
        {
          name: 'Completed',
          titleBg: '#1CE882',
          fixed: false,
          list: [
            {
              order: 7,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 8,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 9,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
          ],
        },
        {
          name: 'Cancelled',
          titleBg: '#F59914',
          fixed: false,
          list: [
            {
              order: 10,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 11,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
            {
              order: 12,
              imgSrc: require('@/assets/images/user.png'),
              header: 'Fix Responsiveness for new structure 1',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              date: '21 June',
              tagColor: 'red',
              max: 100,
              value: 50,
              assignedUsers: [
                {
                  firstName: 'John',
                  lastName: 'Doe',
                  imageSrc: require('@/assets/images/user.png'),
                },
                {
                  firstName: 'Jane',
                  lastName: 'Smith',
                  imageSrc: require('@/assets/images/user.png'),
                },
              ],
            },
          ],
        },
        // {
        //   name: "Test",
        //   titleBg: "#F12914",
        //   fixed: false,
        //   list: [
        //     {
        //       order: 10,
        //       imgSrc: require("@/assets/images/user.png"),
        //       header: "Fix Responsiveness for new structure 1",
        //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        //       date: "21 June",
        //       max: 100,
        //       value: 50,
        //       assignedUsers: [
        //         {
        //           firstName: "John",
        //           lastName: "Doe",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //         {
        //           firstName: "Jane",
        //           lastName: "Smith",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //       ],
        //     },
        //     {
        //       order: 11,
        //       imgSrc: require("@/assets/images/user.png"),
        //       header: "Fix Responsiveness for new structure 1",
        //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        //       date: "21 June",
        //       max: 100,
        //       value: 50,
        //       assignedUsers: [
        //         {
        //           firstName: "John",
        //           lastName: "Doe",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //         {
        //           firstName: "Jane",
        //           lastName: "Smith",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //       ],
        //     },
        //     {
        //       order: 12,
        //       imgSrc: require("@/assets/images/user.png"),
        //       header: "Fix Responsiveness for new structure 1",
        //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        //       date: "21 June",
        //       max: 100,
        //       value: 50,
        //       assignedUsers: [
        //         {
        //           firstName: "John",
        //           lastName: "Doe",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //         {
        //           firstName: "Jane",
        //           lastName: "Smith",
        //           imageSrc: require("@/assets/images/user.png"),
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    };
  },

  watch: {},

  methods: {},

  created() {},

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.avatar-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  border: solid 1px #ccc;
  align-items: center;
  justify-content: center;
}

.avatar-circle-inner {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.scroll-container {
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 180px); /* Örnek değer, gerektiğinde ayarlayabilirsiniz */

  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

#container {
  flex-wrap: nowrap;
}

.list-group {
  min-height: 20px;
  background-color: rgba(236, 236, 236, 0.24);
  // max-height: 100%;
}

.list-group-item {
  cursor: move;
}

.list-group-item {
  cursor: pointer;
}
.scroll-container::-webkit-scrollbar {
  width: 0px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.width-scrool::-webkit-scrollbar {
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.width-scrool::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(25, 0, 255, 0.5);
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
